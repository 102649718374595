<template>
  <AuctionHeader :activeSearchDesktop="false" :activeSearchMobile="false" />
  <section class="bg-agrogo-green-2">
    <div class="grid grid-cols-3 bg-agrogo-yellow-2 h-20 md:h-24">
      <div class="flex h-auto w-auto items-center justify-end">
        <span
          class="font-nexa font-black text-6xl md:text-7xl text-agrogo-yellow-2"
          >06</span
        >
      </div>
      <div
        class="col-span-2 flex flex-col items-start justify-center ml-5 md:ml-8"
      >
        <span class="font-nexa font-black text-base md:text-2xl mb-3 text-left"
          >Videos <span class="font-normal"> y Fotos</span>
        </span>
        <div class="flex items-center space-x-4 md:space-x-7 md:text-base">
          <span class="icon-punto text-agrogo-green-3"></span>
          <span class="icon-punto text-white"></span>
        </div>
      </div>
    </div>
    <div class="md:mx-16 lg:mx-24 xl:mx-40">
      <div
        class="flex items-center justify-start h-7 bg-agrogo-green-3 pl-6 space-x-3.5 md:mt-12 md:h-14 md:space-x-4 mb-3 md:mb-6"
      >
        <span
          class="icon-img text-white md:text-agrogo-yellow-1 text-sm md:text-2xl"
        ></span>
        <p class="font-nexa font-normal text-sm text-white md:text-2xl">
          Recomendaciones
        </p>
      </div>
      <div
        class="grid grid-cols-1 md:grid-cols-2 mx-6 mb-4 lg:mx-16 md:mb-8 gap-x-16"
      >
        <div class="flex items-start">
          <span class="icon-desplegar drop-down-icon pt-1"></span>
          <p
            class="font-dinpro font-normal text-white text-sm md:text-lg text-left"
          >
            Se permite solo un video por publicación.
          </p>
        </div>
        <div class="flex items-start">
          <span class="icon-desplegar drop-down-icon pt-1"></span>
          <p
            class="font-dinpro font-normal text-white text-sm md:text-lg text-left"
          >
            Se permite solo un video por publicación.
          </p>
        </div>
        <div class="flex items-start">
          <span class="icon-desplegar drop-down-icon pt-1"></span>
          <p
            class="font-dinpro font-normal text-white text-sm md:text-lg text-left"
          >
            Lorem ipsum dolor et sit amet cosecte.
          </p>
        </div>
        <div class="flex items-start">
          <span class="icon-desplegar drop-down-icon pt-1"></span>
          <p
            class="font-dinpro font-normal text-white text-sm md:text-lg text-left"
          >
            Se visualizará solo los primeros 45 seg del video.
          </p>
        </div>
        <div class="flex items-start">
          <span class="icon-desplegar drop-down-icon pt-1"></span>
          <p
            class="font-dinpro font-normal text-white text-sm md:text-lg text-left"
          >
            Tip de foto: Toma la foto de frente con buena iluminación y trata de
            no utilizar el zoom.
          </p>
        </div>
      </div>
    </div>
    <!-- AQUI LIBRERIA DE MUTIMEDIA -->
    <div
      class="flex flex-col space-y-8 items-center justify-center h-96 bg-white"
    >
      <p class="text-black font-nexa font-black">AQUI LIBRERIA DE MUTIMEDIA</p>
      <p class="text-black font-nexa font-black opacity-5">
        PUTO EL QUE LO LEA :v
      </p>
    </div>

    <!-- <div
      class="md:flex items-center md:items-start md:px-5 md:py-6 md:mx-16 lg:mx-24 xl:mx-40"
    >
      <div
        class="flex flex-col px-4 pt-3 md:pt-0 lg:pt-0 h-auto w-auto space-y-3 md:space-y-0 md:w-1/2"
      >
        <div class="flex items-center md:px-8 lg:px-3 md:items-start">
          <a
            class="icon-desplegar transform -rotate-90 text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 md:mt-0.5"
          ></a>
          <a
            class="font-dinpro font-normal text-white text-sm text-left lg:text-base"
            >Se permite solo un video (formato mp4) por publicación.</a
          >
        </div>
        <div class="flex items-center md:px-8 lg:px-3 md:items-start">
          <a
            class="icon-desplegar transform -rotate-90 text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 lg:mt-1"
          ></a>
          <a
            class="font-dinpro font-normal text-white text-sm text-left lg:text-base"
            >Se visualizará el video de primero.</a
          >
        </div>
      </div>
      <div
        class="flex flex-col px-4 pt-3 pb-5 md:pt-0 lg:pt-0 h-auto w-auto space-y-3 md:space-y-0 md:px-0 lg:px-0 md:justify-between md:w-1/2"
      >
        <div class="flex items-center md:px-8 md:items-start">
          <a
            class="icon-desplegar transform -rotate-90 text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 md:mt-0.5"
          ></a>
          <a
            class="font-dinpro font-normal text-white text-sm text-left lg:text-base"
            >Se visualizará solo los primeros 45 seg del video.</a
          >
        </div>
        <div class="flex items-center md:px-8 md:items-start">
          <a
            class="icon-desplegar transform -rotate-90 text-agrogo-yellow-1 mr-2 text-xs transition-all hover:translate-y-1 md:mt-0.5"
          ></a>
          <a
            class="font-dinpro font-normal text-white text-sm text-left lg:text-base"
            >Tip de foto: Toma la foto de frente con buena iluminación y trata
            de no utilizar el zoom.</a
          >
        </div>
      </div>
    </div> -->
    <div
      class="bg-agrogo-yellow-1 h-14 md:h-20 flex items-center justify-center space-x-3 md:space-x-4 lg:space-x-4"
    >
      <button @click="previus()" type="button" class="btn-primary">
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
      <button @click="next()" type="button" class="btn-primary">
        Siguiente
        <span
          class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
        ></span>
      </button>
    </div>
  </section>
</template>
<script>
import AuctionHeader from "@/components/header/HeaderAuction";
export default {
  components: {
    AuctionHeader,
  },
};
</script>
